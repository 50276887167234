<template>
  <div>
    <div class="mainHeader">
      <div class=" pt-0 pb-0">
        <div class="row">
          <div class="col-sm-64 col-8">
            <router-link :to="{ name: 'Home'}">
              <img src="../assets/img/uu_logo.png"
                   class="logo">
              <img src="../assets/img/uu_ecomerce.png"
                   class="logo">
            </router-link>
            <a href="/Endless_WebGL"
               target="_blank">
              <img src="../assets/icon/game icon.png"
                   alt="เล่นเกมส์"
                   class="logo">
            </a>
          </div>

          <div class="col-sm-4 text-right col-4">

            <MemberHeader/>
          </div>

        </div>
      </div>
    </div>
    <div class="mainMenu mt-2">
      <div class="container pt-0 pb-0">
        <MainMenu/>
      </div>
    </div>
  </div>
</template>

<script>
import MemberHeader from '@/views/Member/inc/MemberHeader'
import MainMenu from '@/inc/MainMenu'

export default {

  components: {
    MemberHeader,
    MainMenu
  },

  data: () => ({}),

  computed: {},

  watch: {},
  methods: {},
  created() {

  },
}
</script>
