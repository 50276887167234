import Vue from "vue";

let globalData = new Vue({
  data: {
    $LOADING: false,
    $FULL_LOADING: false,
    $ERROR_TEXT: null,
    $ERROR : null,
  },
});
Vue.mixin({
  computed: {
    pageNo() {
      return this.$route.params.pageNo;
  },
    access() {
      return this.$route.params.access;
    },

    item_id() {
      return this.$route.params.item_id;
    },

    slug() {
      return this.$route.params.slug;
    },
    $FULL_LOADING: {
      get: function() {
        return globalData.$data.$FULL_LOADING;
      },
      set: function(newValue) {
        globalData.$data.$FULL_LOADING = newValue;
      },
    },
    $LOADING: {
      get: function() {
        return globalData.$data.$LOADING;
      },
      set: function(newValue) {
        globalData.$data.$LOADING = newValue;
      },
    },
    $ERROR_TEXT: {
      get: function() {
        return globalData.$data.$ERROR_TEXT;
      },
      set: function(newValue) {
        globalData.$data.$ERROR_TEXT = newValue;
      },
    },
     $ERROR: {
      get: function() {
        return globalData.$data.$ERROR;
      },
      set: function(newValue) {
        globalData.$data.$ERROR = newValue;
      },
    },
  },

  methods: {
    setPageNo(page) {
      let path = this.$router.currentRoute.path 
      let pt = path.split("/")
      pt.pop() 
      let newPath = pt.join('/') + "/"+ page 
      window.history.pushState("", "", newPath);
    },
    getFileUpload(file) {
      let size = file.size / (1024 * 1024);

      return { name: file.name, size: size.toFixed(2) };
    },

    setMeta(meta) {

      const descEl = document.querySelector('head meta[name="description"]');
      const keywordEl = document.querySelector('head meta[name="keywords"]');
      const titleEl = document.querySelector('head title');
    
      if(meta.desc != null) { 
      descEl.setAttribute('content', meta.desc);
      }
      if(meta.title != null) { 
      titleEl.textContent = meta.title;
      }
      if(meta.keyword != null) { 
        descEl.setAttribute('content', meta.keyword);
      }
    
    },
        checkApi(res) {
    
          this.$FULL_LOADING = null;
    
          if(res.data.meta) {
            this.setMeta(res.data.meta)
          }
    
          if (res.data.reqUrl) {
            console.log(res.data.reqUrl);
            res.data.reqUrl = null;
          }
          if(res.data.token) {
            res.data.token = null
          }
          console.log(res.data);
         
    
          if (!res.data.rs) {
            this.$ERROR = true;
            this.$ERROR_TEXT = res.data;
          }
          if(res.data.text) {
            if(res.data.text != 'none')  {
              this.alert(res)
            }
          }
    
        },
    setCookie(key, value) { 
      localStorage.setItem(this.$C_COOKIE + key, value);
    },
    getCookie(key) {
      return localStorage.getItem(this.$C_COOKIE + key);
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
   
    alert(res) {
      var type = res.data.alert_type;
      var msg = res.data.text;
      
      let desc = "";
      if (res.data.desc) {
        desc = res.data.desc;
      }

      this.$toast.add({
        severity: type,
        summary: msg,
        detail: desc,
        life: 5000,
      });
    },

    textareaString(str) {
      var regex = /<br\s*[\/]?>/gi;
      return str.replace(regex, "");
    },

    lg(data) {
      console.log(data);
    },
    random() {
      return Math.floor(Math.random() * 10000 + 1);
    },

    ie11() {
      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      return isIE11;
    },

    jsonInt(json) {
      if (json) {
        var result = json.map(function(x) {
          return parseInt(x, 10);
        });
      } else {
        var result = [];
      }

      return result;
    },

    itemSplit(items, page, perPage) {
      let it = [];
      if (items) {
        let from = page * perPage - perPage;
        let to = page * perPage;

        it = items.slice(from, to);
      }

      return it;
    },
    go(goto) {
      var route = this.$router.currentRoute.fullPath;
      Vue.prototype.$SAME_PAGE = true;
      if (goto != route) {
        this.$router.replace(goto);
      }
    },

    sendOtp(sms) {
     
     console.log(sms)
      let data = {
          sender: sms.sender,
          telno: sms.phone,
          text: sms.text
      }

      this.$axios
          .post(sms.url, data, {
              auth: {
                  username: sms.username,
                  password: sms.password
              }
          })
          .then(function (res) {
              console.log(res.data);

          })
          .catch(function (error) {
              console.log(error);

          });

  },


  },
});
