<template>
  <div>
    <div v-if="!$vuetify.breakpoint.mobile">
      <v-tabs center-active
              v-if="navbar"
              v-model="activeTab"
              active-class="menuActive">
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab :to="{ name: 'Home'}"
               class="homeMenu">หน้าหลัก
        </v-tab>

        <v-tab :to="{name :'Category',params:{category_slug:'UU-E-Learning'}}">ห้องเรียน UU E-Learning</v-tab>

        <v-tab :to="{name :'Category',params:{category_slug:'cartoon'}}">วิดีโอสื่อการ์ตูน</v-tab>

        <v-tab :to="{name :'Category',params:{category_slug:'news'}}">ข่าวจากพี่ยูยู</v-tab>

        <v-spacer></v-spacer>
        <!--        <v-tab><a href="/Endless_WebGL"-->
        <!--                  target="_blank"> เกมวิ่ง</a></v-tab>-->
        <v-tab><a href="https://uu.co.th/th"
                  target="_blank"> :: กลับสู่เว็บไซต์หลัก</a></v-tab>
      </v-tabs>
    </div>

    <div v-if="$vuetify.breakpoint.mobile">
      <v-btn icon
             @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>

    <v-navigation-drawer v-model="drawer"
                         absolute
                         temporary>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item :to="{ name: 'Home'}">
          <v-list-item-title> หน้าหลัก
          </v-list-item-title>

        </v-list-item>
        <v-list-item :to="{name :'Category',params:{category_slug:'UU-E-Learning'}}">
          <v-list-item-title>
            ห้องเรียน UU E-Learning
          </v-list-item-title>

        </v-list-item>
        <v-list-item :to="{name :'Category',params:{category_slug:'cartoon'}}">
          <v-list-item-title>
            วิดีโอสื่อการ์ตูน
          </v-list-item-title>

        </v-list-item>

        <v-list-item :to="{name :'Category',params:{category_slug:'news'}}">
          <v-list-item-title>
            ข่าวจากพี่ยูยู
          </v-list-item-title>

        </v-list-item>

        <!--        <v-list-item>-->
        <!--          <v-list-item-title>-->
        <!--            <a href="/Endless_WebGL"-->
        <!--               target="_blank">-->
        <!--              เกมวิ่ง-->
        <!--            </a>-->
        <!--          </v-list-item-title>-->

        <!--        </v-list-item>-->

      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  props: {},
  data: () => ({
    drawer: null,
    data: {
      keyword: null
    },
    navbar: null,
    activeTab: null
  }),

  watch: {
    activeTab(val) {
      console.log(val)
    }

  },

  methods: {
    search() {
      console.log(this.data)

    },

    getMenu() {

      let url = 'misc/navbar'
      let $vm = this
      this.$API
          .post(url, this.formData())
          .then(function (res) {
            $vm.checkApi(res)
            $vm.navbar = res.data.nav

          })
          .catch(function (error) {
            console.log(error)
          })

    },

  },
  created() {

    this.getMenu()

  },

}
</script>
