import Vue from 'vue'
import VueRouter from 'vue-router'
 

Vue.use(VueRouter)



const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/MainPage.vue"),
  },

  {
    path: "/baned",
    name: "Ban",
    component: () => import("../views/MainPage.vue"), 
  }, 

  {
    path: "/certificate",
    name: "BaiCer",
    component: () => import("../views/BaiCer.vue"), 
  }, 

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/MainPage.vue"), 
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/MainPage.vue"), 
  },
  {
    path: "/emailVerified/:code",
    name: "EmailVerified",
    component: () => import("../views/Member/EmailVerified.vue"), 
  }, 
  {
    path: "/emailForgotPassword/:code",
    name: "EmailForgotPassword",
    component: () => import("../views/Member/EmailForgotPassword.vue"), 
  }, 
  
  


  {
    path: "/TestContest/:item_id",
    name: "TestContest",
    component: () => import("../views/TestContest/TestContest.vue"), 
  }, 
  {
    path: "/:category_slug/:item_id.html",
    name: "Content",
    component: () => import("../views/Content/Content.vue"), 
  }, 

  {
    path: "/account/:slug",
    name: "Account",
    component: () => import("../views/Account/Account.vue"), 
  }, 

  {
    path: "/:category_slug//",
    name: "Category",
    component: () => import("../views/Category/Category.vue"), 
  }, 
  {
    path: "/:category_slug/:sub_slug//",
    name: "CategorySub",
    component: () => import("../views/Category/Category.vue"), 
  }, 
  
  {
    path: "/:category_slug/:page",
    name: "CategoryPage",
    component: () => import("../views/Category/Category.vue"), 
  }, 
  {
    path: "/:category_slug/:sub_slug/:pageNo",
    name: "CategorySubPage",
    component: () => import("../views/Category/Category.vue"), 
  }, 
  
  {
    path: "/:slug",
    name: "Page",
    component: () => import("../views/Page/Page.vue"), 
  },

];

    Vue.prototype.$KEY = null;

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: "history",
  base: process.env.VUE_APP_DIR,
  routes,
});

router.beforeEach((to, from, next) => {
  Vue.prototype.$KEY = Math.random();

  if (to.matched.some((record) => record.meta.reuse === true)) {
    Vue.prototype.$KEY = null;
  } else {
    /*  if(from.name != 'Account') {
       Vue.prototype.$KEY = Math.random();
      } */
  }
  next();
});

export default router
