<template>
  <div>
    <v-app>
      <div class="container pt-0 mt-0  mb-0 pb-0">
        <Header/>
      </div>
      <div class="container pt-0  ">

        <Toast position="top-center"/>

        <div v-if="$MEMBER_ONLY_TEXT"
             class="memberOnly">เนื้อหาเฉพาะสมาชิกเท่านั้นค่ะ
        </div>

        <router-view :key="$KEY"></router-view>
      </div>

      <v-snackbar v-model="$ERROR"
                  timeout="-1"
                  :multi-line="true"
                  height="200">
        {{ $ERROR_TEXT }}

        <template v-slot:action="{ attrs }">
          <v-btn color="yellow"
                 text
                 v-bind="attrs"
                 @click="$ERROR = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay :value="$FULL_LOADING">
        <v-progress-circular indeterminate
                             size="64"></v-progress-circular>
      </v-overlay>
      <div class="pt-5"></div>
      <div class="text-center"><img src="@/assets/img/footer_cartoon.gif"></div>

      <div class="footer ">
        <v-footer color="#293747"
                  padless>
          <div class="footerContent">
            <div class="row start">
              <div class="col-sm-4">
                <img src="@/assets/img/logo_footer.png">
                <div><b>บริษัท ยูนิเวอร์แซล ยูทีลิตี้ส์ จำกัด (มหาชน)</b></div>

                1 อาคารอีสท์วอเตอร์ ชั้นที่ 18 ซ.วิภาวดีรังสิต 5 แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900

              </div>
              <div class="col-sm-5">
                <div class="pb-2">
                  <router-link :to="{name:'Page',params:{slug:'about-us'}}">
                    เกี่ยวกับเรา
                  </router-link>
                </div>
                <div class="pb-2">
                  <router-link :to="{name:'Page',params:{slug:'contact-us'}}">
                    ติดต่อเรา
                  </router-link>
                </div>
                <div class="pb-2">
                  <router-link :to="{name:'Page',params:{slug:'howto'}}">
                    วิธีการใช้งาน
                  </router-link>
                </div>
                <div class="pb-2">
                  <router-link :to="{name:'Page',params:{slug:'faq'}}">
                    คำถามที่พบบ่อย (FAQ)
                  </router-link>
                </div>
                <div class="pb-2">
                  <router-link :to="{name:'Page',params:{slug:'privacy-policy'}}">
                    เงื่อนไขการใช้งาน
                  </router-link>
                </div>

              </div>
              <div class="col-sm-3 text-center pt-3">

                <a href="#"
                   target="_blank"
                   class="ma-2"><img src="@/assets/img/facebook.png"/></a>
                <a href="#"
                   target="_blank"
                   class="ma-2"><img src="@/assets/img/line.png"/></a>
                <a href="#"
                   target="_blank"
                   class="ma-2"><img src="@/assets/img/youtube.png"/></a>

                <div class="mt-2">
                  <v-btn rounded
                         color="primary"
                         dark
                         outlined
                         :to="{name:'Page',params:{slug:'contact-us'}}">
                    ติดต่อเรา
                  </v-btn>
                </div>

              </div>
            </div>
          </div>
        </v-footer>
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from './inc/Header'

export default {
  name: 'App',

  components: {

    Header

  },

  data: () => ({}),
  computed: {},

  watch: {},
  methods: {

    setVisit() {
      let $vm = this
      var url = 'misc/visit'

      this.$API
          .post(url)
          .then(function (res) {
            $vm.checkApi(res)
            if (res.data.VISIT_ID)
              $vm.setCookie($vm.$C_VISIT, res.data.VISIT_ID)

          })
          .catch(function (error) {
            console.log(error)
          })
    }

  },
  created() {
    let VISIT_ID = this.getCookie(this.$C_VISIT)
    console.log(VISIT_ID)
    if (!VISIT_ID) {
      this.setVisit()
    }
  },
}
</script>
