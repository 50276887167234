import Vue from "vue";

import PrimeVue from "../components/config/PrimeVue";
//import AutoComplete from "../components/autocomplete/AutoComplete";
//import Accordion from "../components/accordion/Accordion";
//import AccordionTab from "../components/accordiontab/AccordionTab";
//import Avatar from "../components/avatar/Avatar";
//import AvatarGroup from "../components/avatargroup/AvatarGroup";
//import Badge from "../components/badge/Badge";
//import BadgeDirective from "../components/badgedirective/BadgeDirective";
//import BlockUI from "../components/blockui/BlockUI";
//import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import Button from "../components/button/Button";
import Calendar from "../components/calendar/Calendar";
//import Card from "../components/card/Card";
//import Carousel from "../components/carousel/Carousel";
//import CascadeSelect from "../components/cascadeselect/CascadeSelect";
//import Chart from "../components/chart/Chart";
import Checkbox from "../components/checkbox/Checkbox";
//import Chip from "../components/chip/Chip";
//import Chips from "../components/chips/Chips";
//import ColorPicker from "../components/colorpicker/ColorPicker";
import Column from "../components/column/Column";
//import ColumnGroup from "../components/columngroup/ColumnGroup";
//import ConfirmDialog from "../components/confirmdialog/ConfirmDialog";
//import ConfirmPopup from "../components/confirmpopup/ConfirmPopup";
//import ConfirmationService from "../components/confirmationservice/ConfirmationService";
//import ContextMenu from "../components/contextmenu/ContextMenu";
import DataTable from "../components/datatable/DataTable";
//import DataView from "../components/dataview/DataView";
//import DataViewLayoutOptions from "../components/dataviewlayoutoptions/DataViewLayoutOptions";
//import DeferredContent from "../components/deferredcontent/DeferredContent";
import Dialog from "../components/dialog/Dialog";
//import Divider from "../components/divider/Divider";
//import Dropdown from "../components/dropdown/Dropdown";
//import Editor from "../components/editor/Editor";
//import Fieldset from "../components/fieldset/Fieldset";
//import FileUpload from "../components/fileupload/FileUpload";
////import FullCalendar from "../components/fullcalendar/FullCalendar";
//import InlineMessage from "../components/inlinemessage/InlineMessage";
//import Inplace from "../components/inplace/Inplace";
 import InputMask from "../components/inputmask/InputMask";
 import InputNumber from "../components/inputnumber/InputNumber";
//import InputSwitch from "../components/inputswitch/InputSwitch";
import InputText from "../components/inputtext/InputText";
//import Knob from "../components/knob/Knob";
//import Listbox from "../components/listbox/Listbox";
//import MegaMenu from "../components/megamenu/MegaMenu";
import Menu from "../components/menu/Menu";
import Menubar from "../components/menubar/Menubar";
//import Message from "../components/message/Message";
import MultiSelect from "../components/multiselect/MultiSelect";
//import OrderList from "../components/orderlist/OrderList";
//import OrganizationChart from "../components/organizationchart/OrganizationChart";
//import OverlayPanel from "../components/overlaypanel/OverlayPanel";
import Paginator from "../components/paginator/Paginator";
//import Panel from "../components/panel/Panel";
//import PanelMenu from "../components/panelmenu/PanelMenu";
//import Password from "../components/password/Password";
//import PickList from "../components/picklist/PickList";
//import ProgressBar from "../components/progressbar/ProgressBar";
//import ProgressSpinner from "../components/progressspinner/ProgressSpinner";
//import Rating from "../components/rating/Rating";
import RadioButton from "../components/radiobutton/RadioButton";
//import Row from "../components/row/Row";
//import Ripple from "../components/ripple/Ripple";
//import ScrollPanel from "../components/scrollpanel/ScrollPanel";
//import ScrollTop from "../components/scrolltop/ScrollTop";
//import SelectButton from "../components/selectbutton/SelectButton";
//import Skeleton from "../components/skeleton/Skeleton";
//import Slider from "../components/slider/Slider";
//import Sidebar from "../components/sidebar/Sidebar";
//import SplitButton from "../components/splitbutton/SplitButton";
//import Splitter from "../components/splitter/Splitter";
//import SplitterPanel from "../components/splitterpanel/SplitterPanel";
//import Steps from "../components/steps/Steps";
//import TabMenu from "../components/tabmenu/TabMenu";
//import TabView from "../components/tabview/TabView";
//import TabPanel from "../components/tabpanel/TabPanel";
//import Tag from "../components/tag/Tag";
//import Terminal from "../components/terminal/Terminal";
//import Textarea from "../components/textarea/Textarea";
//import TieredMenu from "../components/tieredmenu/TieredMenu";
//import Timeline from "../components/timeline/Timeline";
//import Tree from "../components/tree/Tree";
//import TreeTable from "../components/treetable/TreeTable";
import Toast from "../components/toast/Toast";
import ToastService from "../components/toastservice/ToastService";
//import Toolbar from "../components/toolbar/Toolbar";
//import Tooltip from "../components/tooltip/Tooltip";
//import ToggleButton from "../components/togglebutton/ToggleButton";
//import TriStateCheckbox from "../components/tristatecheckbox/TriStateCheckbox";
//import Galleria from "../components/galleria/Galleria";

//import AppInputStyleSwitch from "../AppInputStyleSwitch";

import "../assets/styles/primevue.css";
//import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
//import "prismjs/themes/prism-coy.css";
//import "../assets/styles/flags.css";

//import Vuelidate from "vuelidate";

Vue.use(PrimeVue, { ripple: true });
//Vue.use(Vuelidate);
Vue.use(ToastService);
//Vue.use(ConfirmationService);

//Vue.directive("badge", BadgeDirective);
//Vue.directive("tooltip", Tooltip);
//Vue.directive("ripple", Ripple);

Vue.prototype.$appState = Vue.observable({
  inputStyle: "outlined",
  darkTheme: false,
});

//Vue.config.productionTip = false;

//Vue.component("Accordion", Accordion);
//Vue.component("AccordionTab", AccordionTab);
//Vue.component("Avatar", Avatar);
//Vue.component("AvatarGroup", AvatarGroup);
//Vue.component("AutoComplete", AutoComplete);
//Vue.component("Badge", Badge);
//Vue.component("BlockUI", BlockUI);
//Vue.component("Breadcrumb", Breadcrumb);
Vue.component("Button", Button);
Vue.component("Calendar", Calendar);
//Vue.component("Card", Card);
//Vue.component("Carousel", Carousel);
//Vue.component("CascadeSelect", CascadeSelect);
//Vue.component("Chart", Chart);
Vue.component("Checkbox", Checkbox);
//Vue.component("Chip", Chip);
//Vue.component("Chips", Chips);
//Vue.component("ColorPicker", ColorPicker);
Vue.component("Column", Column);
//Vue.component("ColumnGroup", ColumnGroup);
//Vue.component("ConfirmDialog", ConfirmDialog);
//Vue.component("ConfirmPopup", ConfirmPopup);
//Vue.component("ContextMenu", ContextMenu);
Vue.component("DataTable", DataTable);
//Vue.component("DataView", DataView);
//Vue.component("DataViewLayoutOptions", DataViewLayoutOptions);
//Vue.component("DeferredContent", DeferredContent);
Vue.component("Dialog", Dialog);
//Vue.component("Divider", Divider);
//Vue.component("Dropdown", Dropdown);
//Vue.component("Editor", Editor);
//Vue.component("Fieldset", Fieldset);
//Vue.component("FileUpload", FileUpload);

//Vue.component("InlineMessage", InlineMessage);
//Vue.component("Inplace", Inplace);
 Vue.component("InputMask", InputMask);
 Vue.component("InputNumber", InputNumber);
//Vue.component("InputSwitch", InputSwitch);
Vue.component("InputText", InputText);
//Vue.component("Knob", Knob);
//Vue.component("Listbox", Listbox);
//Vue.component("MegaMenu", MegaMenu);
Vue.component("Menu", Menu);
Vue.component("Menubar", Menubar);
//Vue.component("Message", Message);
Vue.component("MultiSelect", MultiSelect);
//Vue.component("OrderList", OrderList);
//Vue.component("OrganizationChart", OrganizationChart);
//Vue.component("OverlayPanel", OverlayPanel);
Vue.component("Paginator", Paginator);
//Vue.component("Panel", Panel);
//Vue.component("PanelMenu", PanelMenu);
//Vue.component("Password", Password);
//Vue.component("PickList", PickList);
//Vue.component("ProgressBar", ProgressBar);
//Vue.component("ProgressSpinner", ProgressSpinner);
Vue.component("RadioButton", RadioButton);
//Vue.component("Rating", Rating);
//Vue.component("Row", Row);
//Vue.component("ScrollPanel", ScrollPanel);
//Vue.component("ScrollTop", ScrollTop);
//Vue.component("SelectButton", SelectButton);
//Vue.component("Slider", Slider);
//Vue.component("Sidebar", Sidebar);
//Vue.component("Skeleton", Skeleton);
//Vue.component("SplitButton", SplitButton);
//Vue.component("Splitter", Splitter);
//Vue.component("SplitterPanel", SplitterPanel);
//Vue.component("Steps", Steps);
//Vue.component("TabView", TabView);
//Vue.component("TabPanel", TabPanel);
//Vue.component("TabMenu", TabMenu);
//Vue.component("Tag", Tag);
//Vue.component("Terminal", Terminal);
//Vue.component("Textarea", Textarea);
//Vue.component("TieredMenu", TieredMenu);
//Vue.component("Timeline", Timeline);
Vue.component("Toast", Toast);
//Vue.component("Toolbar", Toolbar);
//Vue.component("ToggleButton", ToggleButton);
//Vue.component("Tree", Tree);
//Vue.component("TreeTable", TreeTable);
//Vue.component("TriStateCheckbox", TriStateCheckbox);
////Vue.component("Galleria", Galleria);

////Vue.component("AppInputStyleSwitch", AppInputStyleSwitch);
