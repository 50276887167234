<template>
<div v-if="items">

    <div class="col-12" v-for="(item,i) in items" :key="i">
        <div class="pb-0 text-center mb-3">
                <div v-html="item.code"></div>
            <div v-if="item.caption != ''">
                <div class="text-center" v-html="item.caption"> </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    components: {

    },
    props: {
        items: null,
        pageOption: null

    },
    data: () => ({
        index: null 

    }),
    methods: {

      

    },
    created() {

   

    },

};
</script>
