import Vue from "vue";
import App from "./App.vue";
import router from "./router";
 import vuetify from "./plugins/vuetify";
 import "vuetify/dist/vuetify.min.css";
import store from './store'


 

import Loading from '@/inc/Comp/Loading'
import Slide from '@/inc/pageOption/Slide'
import Html from '@/inc/Html'
import ContentListBox from '@/views/Content/ContentListBox'
import TestContestListBox from '@/views/TestContest/TestContestListBox'

import NotAllow from '@/inc/Comp/NotAllow'

Vue.component("NotAllow", NotAllow);
Vue.component("Loading", Loading);
Vue.component("Slide", Slide);
Vue.component("Html",Html);
Vue.component("ContentListBox",ContentListBox);
Vue.component("TestContestListBox",TestContestListBox);

import dealerApi from "./Core/dealerApi.js";
Vue.prototype.$dealerApi = dealerApi;

 
 

 import { serialize } from "object-to-formdata";
 Vue.prototype.$formData = serialize;

 
import moment from "moment";

require("moment/locale/th");

// Choose Locale
moment.locale("th");

Vue.prototype.$moment = moment;
 
Vue.config.productionTip = false

import "./assets/css/custom.css";
 

Vue.prototype.$C_VISIT = process.env.VUE_APP_VISIT;
Vue.prototype.$C_COOKIE = process.env.VUE_APP_COOKIE;
Vue.prototype.$C_TOKEN = process.env.VUE_APP_TOKEN;
Vue.prototype.$C_LOGIN = process.env.VUE_APP_LOGIN;
Vue.prototype.$C_COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;
Vue.prototype.$C_MAIN_SITE = process.env.VUE_APP_MAIN_SITE;

 

import axios from "axios";
import "./Core/function";
import "./plugins/prime.js"; 
import "./Core/canvas.js"; 
import "./Core/memberFunction.js";
import "./Core/custom_function.js";
import "./assets/css/core.css"; 
 
import "./assets/css/custom.css";
import "./assets/css/responsive.css"; 


Vue.prototype.$axios = axios;

Vue.prototype.$MAIN = axios.create({
  baseURL: process.env.VUE_APP_MAIN ,
  timeout: 60 * 4 * 1000,
});

Vue.prototype.$API = axios.create({
  baseURL: process.env.VUE_APP_WWW + "apiFront/",
  timeout: 60 * 4 * 1000,
});
 

router.beforeEach(function(to, from, next) {
  window.scrollTo(0, 0);
  next();
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
