import axios from "axios";
import Vue from "vue";

const domain = "";

const apiUrl = process.env.VUE_APP_DEALER_API;
const apiKey = process.env.VUE_APP_DEALER_API_KEY;

export default axios.create({
  baseURL: apiUrl,

  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${apiKey}`,
  },
});

/* 
fetch("https://app.yamaha-motor.co.th/dealer-api/dealers", {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer bmQyZDI2U3Y0VHdMc3c4MlZrQ1NiNjRhV095Y0pqdTU=",
  } 
})
  .then((response) => response.json())
  .then((json) => console.log(json)); */
