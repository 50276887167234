import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const storeMember = new Vuex.Store({
        strict: true,
        state: {

                member: {
                        member_id: null,
                        dealer_id: null,
                        id_card: null,
                        firstname: null,
                        lastname: null,
                        dealer_name: null,
                        token: null,
                        picture: null,
                        username: null,
                        member_id: null,
                        time: null,
                        point: {
                                member_point:0,
                                dealer_point:0,
                        }
                },
                member_id:null,
                


        },
        mutations: {
                set(state,   member_data  ) { 
                        state.member = member_data 
                },
                setPoint(state,   member_point  ) { 
                        state.member.point = member_point
                },
                setId(state,   member_id  ) { 
                        state.member_id = member_id
                },
                setLogout(state,   member_id  ) { 
                        state.member.token = null 
                }

        },
        actions: {

        },
        getters: {

                get: state => {
                        return state.member
                },
                getId: state => {
                        return state.member_id
                }

        }
});



export default storeMember  