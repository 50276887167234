<template>
<div v-if="item">
    <div class="pa-5 pb-0 html">

        <div v-if="pageOption.video">
            <Video :items="pageOption.video" :pageOption="pageOption" />
        </div>

         <div v-if="pageOption.embed">
            <Embed :items="pageOption.embed" :pageOption="pageOption"  />
        </div>

        <Youtube v-if="item.youtube_id" :youtubeID="item.youtube_id" @endTime="youtubeEndTime" />

        <div v-html="item.html" />

        <div v-if="pageOption.gallery">
            <Gallery :items="pageOption.gallery" :pageOption="pageOption" />
        </div>
    </div>

</div>
</template>

<script>
import Gallery from '@/inc/pageOption/Gallery'
import Video from '@/inc/pageOption/Video'
import Embed from '@/inc/pageOption/Embed'
import Youtube from '@/inc/Youtube'
export default {
  components: {
       Gallery,
       Embed,
       Youtube,
       Video
    },
    props: {
        item: null,
        pageOption: null,
    },
    methods: {

   youtubeEndTime(){
    this.$emit('youtubeEndTime',true)
   }

    } 
};
</script>
