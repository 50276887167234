<template> 
<div>
    <div v-if="!counting && remain != null">{{   remain  }}</div>
    <countdown v-if="counting"  :time="total_time * 60 * 1000" @end="handleCountdownEnd" :transform="transform"><template slot-scope="props">เหลือ {{ props.minutes }}:{{ props.seconds }} นาที</template> </countdown>
    <div v-if="endTime" style="color:red">หมดเวลา</div>
</div>
 
</template>

<script>
 import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

export default {
  props: {
    total_time: null,  
    stopCounter: null,  
  },
    data: function () {
    return {
       counting: true,
       endTime: false,
       remain:null
    };
  },
    computed: {
    stop() { 
      return this.stopCounter;
    } 
  },
  watch: {
    stop(val) {
      if(val) {
        this.counting = false;
      }
    } 
  },

   
      methods: {
        startCountdown: function () {
          this.counting = true;
        },
        handleCountdownEnd: function () {
          this.counting = false;
           this.endTime = true;
           this.remain = null
          this.$emit('endTime', true)
          console.log('END')
        },
        transform(props) {
          
       Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        
        const digits = value < 10 ? `0${value}` : value;

     

        props[key] = `${digits}`;
        this.remain = "เหลือ "+props.minutes+":"+props.seconds+" นาที";

      });

      return props;
    },
      }
  };
</script>
 