<template>
<div v-if="video">

    <div class="col-12" v-for="(item,i) in video" :key="i">
        <v-card class="pb-0">
            <video controlslist="nodownload" controls="controls" width="100%" style="display:block">
                <source :src="item.video" type="video/mp4">
            </video>
            <v-card-text v-if="item.caption != ''">
                <div class="text-center" v-html="item.caption"> </div>
            </v-card-text>
        </v-card>
    </div>

</div>
</template>

<script>
export default {
    components: {

    },
    props: {
        items: null,
        pageOption: null

    },
    data: () => ({
        index: null,
        video: null

    }),
    methods: {

        assignPath() {
            let video = new Array
            if (this.items) {

                for (var j = 0; j < this.items.length; j++) {
                    video[j] = this.items[j];
                    video[j].video = this.pageOption.VIDEO + this.items[j].video

                }
            }
            this.video = video
            console.log(this.video)
        }

    },
    created() {

        this.assignPath();

    },

};
</script>
