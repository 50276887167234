<template>
<div>
 

     <v-card class="mx-auto pa-0" elevation="1" :class="item.css" :style="item.style">

                    <v-card-text class="pa-0">

                        <router-link :to="{name:'Content',params:{item_id:item.content_id}}">
                            <div class="row">
                                <div class="col-sm-5 pa-0"> <img :src="item.picture.picture_small" style="width:100%;" /></div>
                                <div class="col-sm-7 ">
                                    <div class="itemTitle ms  "><span>{{item.title}} </span></div>
                                    <div class="text-center mt-2">
                                        <span v-if="item.pretest=='A'" class="itemPretest">PRE TEST</span>
                                        <span v-if="item.posttest=='A'" class="itemPosttest">POST TEST</span>
                                    </div>
                                </div>

                            </div>
                        </router-link>
                    </v-card-text>
                </v-card>

    
          

  
</div>
</template>

<script>
export default {

    
    props: {
       item:null,
       elevation:null,
    
    },
 
};
</script>
