<template>
<div v-if="gallery">

    <CoolLightBox :items="gallery" :index="index" @close="index = null" :fullScreen="true">
        <template v-slot:icon-previous>
            <v-btn icon x-large color="red">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </template>
           <template v-slot:icon-next>
            <v-btn icon  x-large color="red">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template>
           <template v-slot:loading>
            <span style="color:#ffffff">Loading</span>
        </template>
           <template v-slot:close>
            <v-btn icon   color="white" >
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </template>
    </CoolLightBox>

    <div class="row">
        <div class="col-sm-3" v-for="(image, imageIndex) in gallery" :key="imageIndex" @click="index = imageIndex">
            <v-img :src="image.thumb" class="ms" aspect-ratio="1.2"></v-img>
        </div>
    </div>

</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
    components: {
        CoolLightBox
    },
    props: {
        items: null,
        pageOption: null

    },
    data: () => ({
        index: null,
        gallery: null

    }),
    methods: {

        assignImgPath() {
            let gallery = new Array
            if (this.items) {

                for (var j = 0; j < this.items.length; j++) {
                    gallery[j] = this.items[j];
                    gallery[j].src = this.pageOption.PICTURE + this.items[j].src
                    gallery[j].thumb = this.pageOption.PICTURE + this.items[j].thumb
                }
            }
            this.gallery = gallery
        }

    },
    created() {

        this.assignImgPath();

    },

};
</script>
