<template>
          <div class="text-center" v-if="$LOADING">
                <v-progress-circular :width="5" class="mt-5" color="red" indeterminate></v-progress-circular>
            </div>
</template>
<script>
 
export default {
  
    data() {
        return { 

             
    } 
    },
    methods: {
      
    },
}
</script>