<template>
<span>

    <span>

     <BaiCer />

        <v-btn color="blue-grey darken-2" text @click="drawer=true">
            <img :src="$MEMBER.avatar.small" class="avatar" />
            <span class="ml-2" v-if="!$vuetify.breakpoint.mobile">{{$MEMBER.firstname}}</span>
        </v-btn>

    </span>

    <v-navigation-drawer color="rgba(255, 0, 0, 0)" right hide-overlay v-model="drawer" absolute v-if="$MEMBER" temporary height="370px" class="memberMenu" width="330">
        <div class="Box">
            <div class="text-center" style="color:#676767;">
                <img :src="$MEMBER.avatar.big" class="avatar" />
                <div>
                    {{$MEMBER.firstname}} {{$MEMBER.lastname}}

                </div>

            </div>

            <v-list nav dense class="mt-2">
                <v-list-item-group v-model="selectedItem" color="red darken-4">
                    <v-list-item :to="{name:'Account',params:{slug:'profile'}}">
                        <v-list-item-icon>
                            <v-icon>mdi-face-man-profile </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>โปรไฟล์</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="{name:'Account',params:{slug:'historyTest'}}">
                        <v-list-item-icon>
                            <v-icon>mdi-alpha-t-box-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ประวัติการสอบ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="{name:'Account',params:{slug:'historyLogin'}}">
                        <v-list-item-icon>
                            <v-icon>mdi-alpha-l-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ประวัติการเข้าระบบ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="{name:'Account',params:{slug:'changePassword'}}">
                        <v-list-item-icon>
                            <v-icon>mdi-key-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>เปลี่ยนรหัสผ่าน</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="goLogout()">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>ออกจากระบบ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
            </v-list>
        </div>
    </v-navigation-drawer>

</span>
</template>

<script>
import BaiCer from '@/views/BaiCer'
export default {
  components: {
        BaiCer
    },

    props: {

    },
    data: () => ({
        group: null,
        drawer: null,
        selectedItem: null

    }),

    watch: {
        drawer(val) {
            if (val) {

            }
        }
    },

    methods: {

        goLogout() {

            let $vm = this
            $vm.$MEMBER_ID = null
            $vm.setCookie(this.$C_TOKEN, null)

            $vm.$STORE_MEMBER.commit("setLogout", null);
            $vm.$STORE_MEMBER.commit("set", null);
            $vm.$STORE_MEMBER.commit("setId", null);

            $vm.$MEMBER = null;

        }

    },
    created() {

    },

};
</script>
