<template>
<div v-if="item.mode=='notAllow'" class="notAllow">
    เนื้อหาจะแสดงให้เห็นเฉพาะผู้ที่ได้รับอนุญาตเท่านั้นค่ะ
</div>
</template>

<script>
export default {

    props: {
        item: null
    }

}
</script>
