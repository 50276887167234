import Vue from "vue";
 
import moment from "moment";

require("moment/locale/th");
 var momentDurationFormatSetup = require("moment-duration-format");
 
 let globalData = new Vue({
   data: {
     $MODAL: null, 
     $CURRENT_CONTENT_ID: null, 
     $POINT: null, 
   },
 });



Vue.mixin({
  computed: {
 
    $CURRENT_CONTENT_ID: {
      get: function() {
        return globalData.$data.$CURRENT_CONTENT_ID;
      },
      set: function(newValue) {
        globalData.$data.$CURRENT_CONTENT_ID = newValue;
      },
    },

    $MODAL: {
      get: function() {
        return globalData.$data.$MODAL;
      },
      set: function(newValue) {
        globalData.$data.$MODAL = newValue;
      },
    },
    $POINT: {
      get: function() {
        return globalData.$data.$POINT;
      },
      set: function(newValue) {
        globalData.$data.$POINT = newValue;
      },
    },
  },

  methods: {
setPointTime(point){
  let $vm=this
  setTimeout(function () {
    if($vm.$CURRENT_CONTENT_ID == point.data_id) { // ป้องกันการเปิดอ่านพร้อมกันหลายๆหน้า
    $vm.setPoint( point)
    }
}, point.time * 1000);
},

setPoint(point){

  let url = "misc/setPoint";
 
  let $vm = this;
 
  const formData = this.$formData(
    point
  );

  this.$API
      .post(url, $vm.formData(formData))
      .then(function (res) { 
          $vm.checkApi(res); 
          $vm.$POINT = res.data.point.alert
          $vm.$MEMBER_POINT = res.data.point.member_point

      })
      .catch(function (error) {
          console.log(error);
        
      });



},
    dateName() {

      let now = new Date();
 
  
var dateStringWithTime = this.$moment(now).format("YYYY-MM-DD_HH-MM-SS");
 
return dateStringWithTime;

    }

  },
});
