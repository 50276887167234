<template>
<span v-if="$MEMBER_ID">

    <v-btn text v-if="$MEMBER.baiCer=='H'" class="ma-0 pa-0" style="min-width:40px;"  disabled>
        <img src="@/assets/img/baiCer.png" style="height:40px" class="gray">
    </v-btn>
    <v-btn text v-if="$MEMBER.baiCer=='A'" class="ma-0 pa-0" style="min-width:40px;" @click="open()">
        <img src="@/assets/img/baiCer.png" style="height:40px">
    </v-btn>

    <v-dialog v-model="dialog" fullscreen hide-overlay scrollable style="background:#ffffff">

        <div class="cerBox" v-if="member">
            <v-toolbar dark color="blue darken-4" height="40" style="max-height:40px;">

                <v-toolbar-title>ใบรับรองทักษะ</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="text-right pr-3">
            <v-btn icon>
                <v-icon @click.preventDefault="gCanvas({type:'image',name:'cer'})" color="pink" large>mdi-image-area</v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon @click.preventDefault="gCanvas({type:'pdf',name:'cer'})" color="teal" large>mdi-file-pdf-box</v-icon>
            </v-btn></div>
            <div class="pageTarget printContent" ref="printContent" style="background:#ffffff;max-width:1024; width:100%; margin:auto;">
                <div class="text-center cer" style="padding:50px; padding-top:100px;background:#ffffff; ">
                    <div class="row">
                        <div class="col-sm-12">
                            <img src="@/assets/img/s_logo.jpg">
                        </div>
                      

                        <div class="col-sm-12 name mt-2 pa-0">
                            {{member.firstname}} {{member.lastname}}
                        </div>

                        <div class="col-sm-12 pa-0">
                            <img src="@/assets/img/s_line.jpg">
                        </div>

                        <div class="col-sm-12 pa-0 cDate">
                            วันที่ {{member.cerDate}}
                        </div>

                  

                        <div class="col-sm-12">
                            <img src="@/assets/img/s_footer.jpg">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-dialog>
</span>
</template>

<script>
export default {

    components: {

    },

    data: () => ({
        dialog: null,
        member: null
    }),
    computed: {

    },

    watch: {

    },
    methods: {

        memberOne() {
            let $vm = this;
            this.loading = true;

            $vm.$FULL_LOADING = true;

            var url =
                "member/cer"
            this.$API
                .post(url, this.formData())
                .then(function (res) {
                    $vm.checkApi(res);
                    $vm.member = res.data.member;

                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        open() {
            this.dialog = true
            this.memberOne()
        }

    },
    created() {

    },
};
</script>
