<template>
<div>
 
    
                <v-card class="mx-auto " :elevation="elevation" :class="item.css" :style="item.style">

                    <v-card-text class="pa-0">
 
                        <router-link :to="{name:'Content',params:{category_slug:item.category_slug,item_id:item.content_id}}">
                            <figure>
                                <img :src="item.picture.picture_small" style="width:100%;" />
                            </figure>

                            <div class="itemTitle ms pa-2"><span>{{item.title}} </span></div>
                        </router-link>
                    </v-card-text>
                </v-card>

  
</div>
</template>

<script>
export default {

    
    props: {
       item:null,
       elevation:null,
    
    },
 
};
</script>
