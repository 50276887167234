<template>
<div class="bannerSlide animate__animated animate__fadeIn" v-if="pageOption">
<div v-if="$vuetify.breakpoint.mobile">
    <v-carousel :continuous="true" :cycle="true" height="auto" v-if="pageOption.slide.items">
        <v-carousel-item class="pt" v-for="(item,i) in pageOption.slide.items" :key="i">
            <div v-if="!item.link_to">
                <img :src="pageOption.PICTURE+item.mobile.picture_big" />
            </div>
            <div v-if="item.link_to">
                <a :href="item.link_to"><img :src="pageOption.PICTURE+item.mobile.picture_big" /></a>
            </div>
        </v-carousel-item>
    </v-carousel>

    <div v-if="pageOption.slide.item">
        <div v-if="!pageOption.slide.item.link_to">
            <img :src="pageOption.PICTURE+pageOption.slide.item.mobile.picture_big" />
        </div>
        <div v-if="pageOption.slide.item.link_to">
            <a :href="pageOption.slide.item.link_to"><img :src="pageOption.PICTURE+pageOption.slide.item.mobile.picture_big" /></a>
        </div>
    </div>
</div>



<div v-if="!$vuetify.breakpoint.mobile">
    <v-carousel :continuous="true" :cycle="true" height="auto" v-if="pageOption.slide.items">
        <v-carousel-item class="pt" v-for="(item,i) in pageOption.slide.items" :key="i">
            <div v-if="!item.link_to">
                <img :src="pageOption.PICTURE+item.desktop.picture_big" />
            </div>
            <div v-if="item.link_to">
                <a :href="item.link_to"><img :src="pageOption.PICTURE+item.desktop.picture_big" /></a>
            </div>
        </v-carousel-item>
    </v-carousel>

    <div v-if="pageOption.slide.item">
        <div v-if="!pageOption.slide.item.link_to">
            <img :src="pageOption.PICTURE+pageOption.slide.item.desktop.picture_big" />
        </div>
        <div v-if="pageOption.slide.item.link_to">
            <a :href="pageOption.slide.item.link_to"><img :src="pageOption.PICTURE+pageOption.slide.item.desktop.picture_big" /></a>
        </div>
    </div>
</div>

</div>
</template>

<script>
export default {

    props: {
        pageOption: null,
    },

};
</script>
