import Vue from 'vue'

import storeMember from '../store/member.js'

Vue.prototype.$STORE_MEMBER = storeMember

let globalData = new Vue({
  data: {
    $AUTH: null,
    $MEMBER_ID: null,
    $GROUP_ID: null,
    $MEMBER: null,
    $MEMBER_POINT: null,
    $MEMBER_ONLY_TEXT: null,
  },
})
Vue.mixin({
  computed: {
    $GROUP_ID: {
      get: function () {
        return globalData.$data.$GROUP_ID
      },
      set: function (newValue) {
        globalData.$data.$GROUP_ID = newValue
      },
    },
    $MEMBER_ONLY_TEXT: {
      get: function () {
        return globalData.$data.$MEMBER_ONLY_TEXT
      },
      set: function (newValue) {
        globalData.$data.$MEMBER_ONLY_TEXT = newValue
      },
    },

    $MEMBER: {
      get: function () {
        return globalData.$data.$MEMBER
      },
      set: function (newValue) {
        globalData.$data.$MEMBER = newValue
      },
    },
    $MEMBER_ID: {
      get: function () {
        return globalData.$data.$MEMBER_ID
      },
      set: function (newValue) {
        globalData.$data.$MEMBER_ID = newValue
      },
    },

    $MEMBER_POINT: {
      get: function () {
        return globalData.$data.$MEMBER_POINT
      },
      set: function (newValue) {
        globalData.$data.$MEMBER_POINT = newValue
      },
    },

  },

  methods: {


    logout() {


      this.setCookie(this.$C_TOKEN, null)
      this.$AUTH = null

    },


    formData(formData, basic) {
      let $vm = this
      let formD = null
      if (!formData) {
        formD = new FormData()
      } else {
        formD = formData
      }

      let VISIT_ID = this.getCookie(this.$C_VISIT)

      formD.append('visit_id', VISIT_ID)
      formD.append('path', this.$router.currentRoute.path)


      if (basic) {
      }

      //formD.append("memberToken", $vm.getCookie($vm.$C_TOKEN));

      return formD
    },


    memberPoint() {
      let $vm = this
      this.loading = true

      var url =
          'account/getPoint'
      this.$API
          .post(url, this.formData())
          .then(function (res) {
            $vm.checkApi(res)
            $vm.$MEMBER_POINT = res.data.point

          })
          .catch(function (error) {
            console.log(error)
          })


    },

    memberOnly(publicContent = false) {
      let re = false
      if (!this.$MEMBER_ID) {
        this.$MEMBER_ONLY_TEXT = true
      } else {
        this.$MEMBER_ONLY_TEXT = null
        re = true
      }
      if (publicContent) {
        this.$MEMBER_ONLY_TEXT = null
        re = true
      }
      return re
    }
  },
})
