<template>
<div v-if="youtubeID"> 
    <div class="vdoBox videoWrapper"> 
    <youtube  :player-vars="playerVars" :video-id="youtubeID" ref="youtube" @playing="playing" :width="640" :height="360" @cued="cued"></youtube>
</div>
 <CountDown :total_time="countTime" v-on:endTime="onEndTime"  class="mt-2" v-if="countTime" style="display:none;" />
</div>
</template>

<script>
import CountDown from '@/inc/Comp/CountDown'
import Vue from 'vue'
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);
export default {

    components: {
        CountDown 
    },
    props:{
        youtubeID:null
    },

    data: () => ({
        //videoId: 'DnfS6DUbG8s',

        countTime:null,
      
        playerVars: {
            autoplay: 0,
            controls: 1,
            playsinline: 1,
            mute: 0,
            //origin: process.env.VUE_APP_YOUTUBE_ORIGIN
        }
    }),

    computed: {
        player() {
            return this.$refs.youtube.player
        } 

    },

    mounted() {
        this.player.addEventListener('onStateChange', this.youtubeControl)
        //this.player.addEventListener('onStateChange', this.youtubStateChange)
    },

    watch: {

    },
    methods: {
        cued() {

        },

          onEndTime() {
      
            console.log("END TIME");

            this.$emit('endTime',true)
        },

       
        playVideo() {
            this.$refs.youtube.player.playVideo()
        },
        playing() {
         console.log('\o/ we are watching!!!')

         this.countTime = 3

        },
        youtubStateChange(youtubeState) {
            //  console.log('youtubeState:', youtubeState.data)
        },
        assPlay(yt) {

            yt.target.playVideo();

        },
        youtubeControl(youtubeState) {
            //console.log('YOUTUBE_STATE : ' + youtubeState)
            /**
             * Youtube States:
             *  -1 - unstarted
             *  0 - ended
             *  1 - playing
             *  2 - paused
             *  3 - buffering
             *  5 - video cued
             */
            let state = youtubeState.data

            // Current Track stopped\finished playing (reached the end of time)
            if (state === -1) {
                //console.log('unstarted');

              //  this.assPlay(youtubeState)

            } else if (state === 0) {
                //console.log('ended');
            } else if (state === 1) {
                //console.log('playing');

            } else if (state === 2) {
                //console.log('paused');
              //  this.assPlay(youtubeState)
            } else if (state === 3) {
                // console.log('buffering');
                //this.assPlay(youtubeState) 
            } else if (state === 5) {
                // console.log('video cued');
                // this.assPlay(youtubeState) 
            }
        },
    },
    created() {

    },
};
</script>
