<template>
  <span>

    <span v-if="!$MEMBER_ID">
      <v-btn outlined
             small
             fab
             color="light-blue darken-3"
             @click="dialog=true">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn text
             @click="dialog=true"
             v-if="!$vuetify.breakpoint.mobile">
        เข้าสู่ระบบ
      </v-btn>
      <v-btn text
             :to="{name:'Register'}"
             v-if="!$vuetify.breakpoint.mobile">
        สมัครสมาชิก
      </v-btn>
    </span>
    <MemberMenu v-if="$MEMBER_ID"/>

    <v-dialog v-model="dialog"
              scrollable
              max-width="500px">
      <v-card class="text-center"
              v-if="step === 'login'">
        <v-toolbar dense
                   elevation="0"
                   color="blue darken-4"
                   dark>

          <v-toolbar-title>เข้าสู่ระบบ</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn text
                 @click="dialog = false">
            ปิดหน้าต่าง
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-2 text-center pa-5">

          <div v-if="!msg_text">
            <div v-html="loginMsg"
                 class="mb-5 mt-5 text-red-500"></div>

            <v-form ref="form"
                    v-model="valid"
                    lazy-validation>

              <div>
                <div style="display: flex; align-items: center; justify-content: center;">
                  <img src="../../../assets/icon/icon.png"
                       alt="เล่นเกมส์"
                       style="width: 32px"
                  >
                  <div style="margin-left: 0.5rem;"> เข้าสู่ระบบโดย</div>
                </div>
                <v-radio-group v-model="type">
                  <v-radio label="เบอร์มือถือ"
                           value="phone"></v-radio>
                  <v-radio label="Username"
                           value="username"></v-radio>
                </v-radio-group>
              </div>

              <div v-if="type === 'phone'">
                <div style="display: flex; align-items: flex-start; justify-content: center;">
                  <img src="../../../assets/icon/mobile.png"
                       alt="Phone"
                       style="width: 48px; margin-right: 0.5rem;"
                  >
                  <v-text-field v-model="phone"
                                label="เบอร์มือถือ"
                                required
                                outlined>

                  </v-text-field>
                </div>

                <v-btn
                    color="success"
                    class="ma-4"
                    type="button"
                    @click="sendOTP">
                  รับ OTP
                </v-btn>
              </div>
              <div v-if="type === 'username'">
                <div style="display: flex; align-items: flex-start; justify-content: center;">
                  <img src="../../../assets/icon/email.png"
                       alt="Email"
                       style="width: 48px; margin-right: 0.5rem;"
                  >

                  <div style="flex: 1">

                    <v-text-field v-model="username"
                                  :rules="nameRules"
                                  label="User ID หรือ E-mail"
                                  required
                                  outlined></v-text-field>

                    <v-text-field type="password"
                                  v-model="password"
                                  :rules="passwordRules"
                                  label="รหัสผ่าน"
                                  required
                                  outlined></v-text-field>
                  </div>
                </div>

                <div class="text-center">

                  <v-btn text
                         class="ma-4"
                         style="display: inline-flex; align-items: flex-start; justify-content: center;"
                         :to="{name:'ForgotPassword'}"
                         @click="dialog=null">
                    <img src="../../../assets/icon/forgot.png"
                         alt="Email"
                         style="width: 24px; margin-right: 0.5rem;"
                    >

                    ลืมรหัสผ่าน
                  </v-btn>
                  <v-btn :disabled="!valid"
                         color="success"
                         class="ma-4"
                         @click="validate">
                    เข้าระบบ
                  </v-btn>

                  <div>
                    <v-btn class="ma-4"
                           text
                           :to="{name:'Register'}"
                           v-if="$vuetify.breakpoint.mobile"
                           @click="dialog=null">
                      สมัครสมาชิก
                    </v-btn>
                  </div>
                </div>
              </div>


            </v-form>

          </div>
          <div v-else
               class="pa-5">
            <div v-html="msg_text"></div>
            <div class="text-center">
              <v-btn color="blue"
                     dark
                     class="ma-4"
                     @click="dialog=null,msg_text=null">
                ปิดหน้าต่าง
              </v-btn>

            </div>
          </div>

        </v-card-text>
      </v-card>

      <v-card class="mx-auto animate__animated animate__zoomIn"
              max-width="600"
              v-if="step === 'OTP'">
        <v-card-text>


          <div class="text-center">ระบุหมายเลข OTP ที่ส่งไปยังเบอร์มือถือของคุณ</div>

          <v-form ref="form"
                  @submit.prevent="loginOTP"
          >

            <v-text-field v-model="otp"
                          label="รหัส OTP"
                          required
                          placeholder="OTP"
                          outlined
                          :rules="otpRules"></v-text-field>

            <div class="flex">

              <v-btn
                  type="submit"
                  color="light-blue darken-4"
                  class="mr-4"
                  style="color:#ffffff;">
                ยืนยัน OTP
              </v-btn>
              <v-btn
                  color="light-blue darken-4"
                  class=""
                  @click="sendOTP"
                  :outlined="true"
                  style="color:#ffffff;">
                ส่ง OTP อีกครั้ง
              </v-btn>
            </div>
          </v-form>


        </v-card-text>
      </v-card>

    </v-dialog>

  </span>
</template>

<script>
import MemberMenu from '@/views/Member/inc/MemberMenu'

export default {
  components: {

    MemberMenu
  },

  props: {},
  data: () => ({
    dialog: null,
    valid: true,
    msg_text: null,
    type: 'phone',

    nameRules: [
      v => !!v || 'โปรดระบุ User ID หรือ E-mail ด้วยค่ะ'
    ],

    passwordRules: [
      v => !!v || 'โปรดระบุ รหัสผ่านด้วยค่ะ'
    ],

    boxLoginClass: null,
    logingIn: false,
    loginMsg: null,

    username: '',
    password: '',

    phone: null,
    step: 'login',
    code: null,
    otp: null,
  }),

  computed: {
    otpRules: function () {
      let $vm = this
      return [
        v => (v === $vm.code) || 'กรุณาระบุรหัส OTP ให้ถูกต้อง'
      ]
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate()
      let $vm = this
      setTimeout(function () {
        if ($vm.valid === true) {
          $vm.login()
        }
      }, 100)
    },

    setMember(data, token) {

      let $vm = this
      this.$MEMBER_ID = data.member.member_id

      $vm.setCookie(this.$C_TOKEN, token)

      this.$API.defaults.headers.common['Authorization'] = `Bearer ${token}`

      if (data.member) {
        $vm.$STORE_MEMBER.commit('set', data.member)
        $vm.$STORE_MEMBER.commit('setId', data.member.member_id)

        // $vm.$ALLOW = data.allow

        $vm.$MEMBER = data.member

        let view = data.member
        //view.token = null
        console.log(view)

        this.$MEMBER_ONLY_TEXT = null

      }
    },

    login() {
      let $vm = this

      $vm.loginMsg = ''
      $vm.msg_text = null

      this.setCookie(this.$C_TOKEN, null)
      const formData = new FormData()
      formData.append('username', $vm.username)
      formData.append('password', $vm.password)

      let VISIT_ID = this.getCookie(this.$C_VISIT)
      formData.append('visit_id', VISIT_ID)
      formData.append('path', this.$router.currentRoute.path)

      var url = 'member/login'
      this.$API
          .post(url, formData)
          .then(function (res) {

            let token = res.data.token
            $vm.checkApi(res)
            $vm.logingIn = false

            $vm.msg_text = res.data.msg_text

            if (res.data.check == false) {

              if (res.data.ban) {
                $vm.dialog = null
                $vm.$router.push({
                  name: 'Ban'
                })
              } else {
                $vm.loginMsg = res.data.loginMsg
              }

            } else {

              $vm.dialog = null

              $vm.setMember(res.data, token)

              $vm.$router.push({
                name: 'Account',
                params: {
                  slug: 'profile'
                }
              })

            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },

    checkMember() {
      let $vm = this
      const formData = new FormData()

      var url = 'member/check'

      this.$API.defaults.headers.common['Authorization'] = `Bearer ${$vm.getCookie($vm.$C_TOKEN)}`

      this.$API
          .post(url, this.formData())
          .then(function (res) {
            let token = res.data.token
            $vm.checkApi(res)
            if (res.data.member) {
              $vm.setMember(res.data, token)

            }
            if (res.data.ban) {
              $vm.$router.push({
                name: 'Ban'
              })
            }

          })
          .catch(function (error) {
            console.log(error)
          })
    },

    sendOTP() {

      let url = 'member/OTP-Login'
      let $vm = this

      const formData = new FormData()
      formData.append('phone', $vm.phone)

      this.$API
          .post(url, formData)
          .then(function (res) {
            $vm.checkApi(res)
            $vm.loginMsg = res.data.msg
            $vm.code = res.data.code
            if ($vm.code !== undefined) {
              $vm.step = 'OTP'
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },

    loginOTP() {

      const formData = new FormData()
      formData.append('code', this.code)

      let url = 'member/OTP-Confirmed'
      let $vm = this
      this.$API
          .post(url, formData)
          .then(function (res) {
            $vm.checkApi(res)

            $vm.setMember(res.data, res.data.member.token)

            $vm.dialog = false

          })
          .catch(function (error) {
            console.log(error)
          })
    },
  },
  created() {
    if (!this.$MEMBER_ID) {
      this.checkMember()
    }
  },

}
</script>
