import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=83ccd510"
import script from "./Slide.vue?vue&type=script&lang=js"
export * from "./Slide.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../usr/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VCarousel,VCarouselItem})
